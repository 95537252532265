'use strict';
// @PLUGIN: Dropdown Checkbox
// Self invoking
// =============================
$('.dropdown-menu').on('click', function(e) {
    // Prevent that a dropdown closes when click on input/checkbox
    if($(this).hasClass('dropdown-menu-form')) {
        e.stopPropagation();
    }
});

// @PLUGIN: Kona Utils
// Self invoking
// =============================

const konaUtils = {

    // Get Browser cookie

    getCookie: (name) => {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = jQuery.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    },

    // Checks if value exist in array

    arrayContains: (str, arr) =>{
        return (arr.indexOf(str) > -1);
    },

    // Gets and object if value of key exists

    getObjectByValue(key, value, arr) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][key] === value ) {
                return arr[i];
            }
        }
        return null;
    },

    // Checks if exists and object with the given value and key

    valueInObject(key, value, arr) {
        return ( this.getObjectByValue(key, value, arr) !== null );
    }
};
// Global Easy Search
$('#global-search').bind('keypress keydown keyup', function(e) {
  let code = e.keyCode || e.which;
  if (code === 13) {
    e.preventDefault();
    return false;
  }
});

const $toggler = $('#global-search');
const options = {
  url: (phrase) => {
    return Urls.location_search();
  },
  getValue: 'name',
  requestDelay: 300,
  minCharNumber: 2,
  adjustWidth: false,
  ajaxSettings: {
    dataType: 'json',
    method: 'GET',
    data: {}
  },
  preparePostData: (data) => {
    data.q = $toggler.val();
    return data;
  },
  template: {
    type: 'custom',
    method: (value, item) => {
      let icon;
      let name;
      let width = '22';
      let mr = 'mr-0';
      let url = '';
      switch (item.category) {
        case 'site':
          if (item.status === 'dead') {
            icon = 'sites_dead_pin_search.png';
          } else {
            icon = 'sites_pin_search.png';
          }
          name = value;
          width = '22';
          mr = 'mr-1';
          url = icon_path + icon;
          break;

        case 'store':
          if (item.is_relocation) {
            icon = 'existing_stores_pin_relocation.png';
          }
          else if (item.status === 'on_the_books') {
            icon = 'existing_stores_pin_on_the_books.png';
          }
          else if (item.status === 'closed') {
            icon = 'existing_stores_pin_closed.png';
          }
          else {
            icon = 'existing_stores_pin_open.png';
          }
          name = item.number + ' - '+ value;
          url = icon_path + icon;
          break;

        case 'neighborhood':
          icon = item.icon;
          url = icon;
          name = value;
          mr = 'mr-1';
          break;
      }
      return '<img src="' + url + '"  class="pb-1 ' + mr + '" width="' + width + '">' +
        '<span>' + name + '</span>' + '<span class="text-muted small ml-1">' +
        item.category_display + '</span>';
    }
  },
  list: {
    maxNumberOfElements: 15,
    onChooseEvent: () => {
      let item = $toggler.getSelectedItemData();
      window.open(item.url, '_blank');
    }
  }
};
$toggler.easyAutocomplete(options);
